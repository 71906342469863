import NPIconButton from '../NPIconButton'
import './NPCardMessage.css'
import { ReactComponent as TickSVG } from '../../icons/tick.svg';
import { ReactComponent as WarningSVG } from '../../icons/exclamation-mark.svg';
import { ReactComponent as ActionSVG } from '../../icons/question-mark.svg';
import { ReactComponent as CarrierSVG } from '../../icons/carrier.svg';
import { ReactComponent as MoneySVG } from '../../icons/money-bag.svg';
import moment from 'moment';
import NPButton from '../NPButton';
/**
 * 
 * @param {text} Mandatory. The text to provide.
 * @param {color} Optional. One of "primary", "complementary", "accent", "accent-mkt". Default is "primary"
 * @param {icon} Optional. One of "ok", "warn", "action", "carrier", "money". Default is null.
 * @param {onOk} Optional. If provided, the widget will show an ok button. This callback will be called when pressesd. Default null.
 * @param {actionTitle} Optional. Name of an action to put as a button.
 * @param {onAction} Optional. Callback to a custom action.
 * @param {date} Optional. If provided, a date formatted YYYYMMDD to display
 * @param {instructions} Optional. Additional text to be shown as small instructions.
 * @param {noBorder} Optional. Pass true to avoid the border
 * @param {backgroundColor} Optional. One of "primary", "complementary", "accent", "accent-mkt". Default is "primary"
 * 
 * @returns 
 */
export function NPCardMessage({ text, color, backgroundColor, icon, onOk, actionTitle, onAction, date, instructions, noBorder }) {

    const messageColor = color ? color : "primary"
    const bckColor = backgroundColor ? backgroundColor : "none"

    let loadedIcon;
    if (icon == 'ok') loadedIcon = <TickSVG />
    else if (icon == 'warn') loadedIcon = <WarningSVG />
    else if (icon == 'action') loadedIcon = <ActionSVG />
    else if (icon == 'carrier') loadedIcon = <CarrierSVG />
    else if (icon == 'money') loadedIcon = <MoneySVG />

    return (
        <div className={`np-card-message ${messageColor} bck-${bckColor} ${icon} ${noBorder ? "no-border" : ''}`}>
            <div className='row mobile-col'>
                <div className='row'>
                    {icon && <div className={`icon ${icon}`}>{loadedIcon}</div>}
                    <div className='text-container'>
                        {date && <div className="date">{moment(date, "YYYYMMDD").format("DD.MM.YYYY")}</div>}
                        <div className='text'>{text}</div>
                        {instructions &&
                            <div className='row'>
                                <div className='instructions'>
                                    {instructions}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {onAction && actionTitle &&
                    <div className='ok-button'><NPButton label={actionTitle} onClick={onAction} /></div>
                }
                {onOk && <div className='ok-button'><NPIconButton image={<TickSVG />} onClick={onOk} /></div>}
            </div>
        </div>
    )
}