import { useEffect, useState } from 'react';
import NPButton from '../../../../comp/NPButton';
import NPPriceInput from '../../../../forms/NPPriceInput'
import { ReactComponent as SendSVG } from '../../../../icons/send.svg'
import './ConversationChatBox.css'
import Popup from 'reactjs-popup';
import NPCard from '../../../../comp/card/NPCard';
import NPLabeledValue from '../../../../comp/NPLabeledValue';
import { HSpacer } from '../../../../comp/layout/HSpacer';
import MarketplaceAPI from '../../../../api/MarketplaceAPI';
import { NPLoadingBar } from '../../../../comp/NPLoadingBar';

export function SellerConversationChatBox({ transaction, onMessageSubmitted, conversationId }) {

    const [showConfirmation, setShowConfirmation] = useState(false)
    const [price, setPrice] = useState(transaction ? transaction.price : 0)
    const [shippingCost, setShippingCost] = useState(transaction && transaction.shippingCost ? transaction.shippingCost : 0);
    const [message, setMessage] = useState()
    const [updating, setUpdating] = useState(false)

    /**
     * Submits the new prices
    */
    const submitNewPrice = async () => {

        const defaultMessage = `Il prezzo aggiornato è di ${price.toLocaleString('it-IT', { style: 'currency', currency: "EUR" })} e le spese di spedizione sono di ${shippingCost.toLocaleString('it-IT', { style: 'currency', currency: "EUR" })}.`

        setUpdating(true)

        await new MarketplaceAPI().postMessage(transaction.id, {
            message: message ? message : defaultMessage,
            price: price,
            shippingCost: shippingCost,
            conversationId: conversationId, 
            partyType: 'seller'
        });

        setUpdating(false)
        setShowConfirmation(false)

        if (onMessageSubmitted) onMessageSubmitted()
    }

    return (
        <div className={`conversation-chat-box`}>

            <div className="answer-title">Vuoi rispondere al messaggio del Compratore e <b>aggiornare il prezzo del bene</b>?</div>

            <div className='box'>
                <textarea
                    className='chatinputbox'
                    placeholder="Inserisci la tua risposta (opzionale)"
                    value={message}
                    onChange={(v) => { setMessage(v.target.value) }}
                    rows="3"
                />
                <div className='inputs-container'>
                    <div className='input-container'>
                        <NPPriceInput label="Prezzo" type="number" prefilled={price} onChange={(v) => { if (v) setPrice(parseFloat(v)); else setPrice(0) }} />
                    </div>
                    <div className='input-container'>
                        <NPPriceInput label="Costi di Spedizione*" type="number" prefilled={shippingCost} onChange={(v) => { if (v) setShippingCost(parseFloat(v)) }} />
                    </div>
                </div>
                <div className='disclaimer'>* Costi di spedizione <b>a carico del Compratore</b>.</div>
                <div className='seller-buttons-container'>
                    <div style={{ flex: 1 }}></div>
                    <NPButton imageLeft={<SendSVG />} label="Invia & Aggiorna prezzi" onClick={() => { setShowConfirmation(true) }} />
                </div>
            </div>

            <Popup open={showConfirmation} onClose={() => { setShowConfirmation(false) }}>
                <div className='conv-chat-box-confirmation-popup'>
                    <NPCard>
                        {!updating &&
                            <>
                                <div className='question'>Sei sicuro di voler aggiornare il prezzo di vendita e i costi di spedizione con i valori seguenti?</div>
                                <div className='values'>
                                    <NPLabeledValue label="Prezzo" value={price} type="currency" />
                                    <NPLabeledValue label="Costi di Spedizione" value={shippingCost} type="currency" />
                                </div>
                                <HSpacer />
                                <div className='buttons-container'>
                                    <NPButton label="Annulla" filled={false} outline={true} onClick={() => { setShowConfirmation(false) }} />
                                    <div style={{ flex: 1 }}></div>
                                    <NPButton label="Conferma" onClick={submitNewPrice} />
                                </div>
                            </>
                        }
                        {updating && <NPLoadingBar label="Aggiornamento in corso.." />}
                    </NPCard>
                </div>
            </Popup>
        </div>
    )
}