import './AdBox.css'

import NPLabeledValue from '../../../comp/NPLabeledValue'
import { ReactComponent as TractorSVG } from '../../../images/tractor-sample.svg'
import { NPLoadingBar } from '../../../comp/NPLoadingBar'

export function AdBox({ loading, title, imageUrl, price, licensePlates }) {

    if (loading) return (
        <div className="ad-box">
            <NPLoadingBar label="Caricamento dati annuncio in corso.." />
        </div>
    )

    return (
        <div className="ad-box">
            <div className="image-container">
                <img src={imageUrl} />
            </div>
            <div className='ad-values'>
                <NPLabeledValue label="Dati dell'annuncio" value={title} size="large" />
                <div className="row">
                    <NPLabeledValue label="Prezzo" value={price} type="currency" notAvailableLabel="Non Disponibile" />
                </div>
            </div>
        </div>
    )
}