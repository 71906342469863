import { useHistory, useLocation, useContext } from 'react-router-dom';
import { useState } from 'react';
import '../Site.css';
import './Header.css';
import { ReactComponent as MenuSVG } from '../icons/menu.svg';
import { ReactComponent as CloseSVG } from '../icons/close.svg';
import { ReactComponent as PleggitTitle } from '../images/logo/pleggit-title.svg';
import { ReactComponent as PleggitLogo } from '../images/logo/logo-no-color.svg';
import { ReactComponent as AffareTrattoreLogo } from '../images/logo/pago-affare-trattore.svg';
import { ReactComponent as ExitSVG } from '../icons/exit.svg';
import Cookies from 'universal-cookie';

const cookies = new Cookies()

export default function Header(props) {

    const [sideMenu, setSideMenu] = useState(false);

    let history = useHistory();
    const location = useLocation();

    const loggedUser = cookies.get("user");

    const onHomeSection = (sectionId) => {
        setSideMenu(false);

        if (location.pathname == '/') {
            props.onHomeSectionScrollTo(sectionId);
        }
        else history.push("/", { scrollTo: sectionId })
    }

    const onClickPricing = () => { setSideMenu(false); history.push("/pricing"); }
    const onClickAboutus = () => { setSideMenu(false); history.push("/aboutus"); }
    const onClickCollab = () => { setSideMenu(false); history.push("/collab"); }
    const onClickPartner = () => { setSideMenu(false); history.push("/partner"); }
    const onClickPrivacy = () => { setSideMenu(false); history.push("/privacy"); }
    const onClickTerms = () => { setSideMenu(false); history.push("/terms"); }
    const onClickSupport = () => { setSideMenu(false); history.push("/support"); }
    const onClickMedia = () => { setSideMenu(false); history.push("/media"); }
    const onClickHome = () => { setSideMenu(false); history.push("/"); }

    const onCloseSideMenu = () => {
        setSideMenu(false)
    }

    const onShowSideMenu = () => {
        setSideMenu(true)
    }

    const logout = () => {

        cookies.remove('user');

        history.push('/');
    }

    const sideMenuComp = (
        <div className={"side-menu" + (sideMenu ? " visible" : " hidden")}>
            <div className="close"><div className="close-button" onClick={onCloseSideMenu}><CloseSVG /></div></div>
            <div className="menu-list">
                <div className="menu-item" onClick={() => { onHomeSection("section-why") }}>Perché</div>
                <div className="menu-item" onClick={() => { onHomeSection("section-how") }}>Come funziona</div>
                <div className="menu-item" onClick={onClickPricing}>Pricing</div>
                <div className="menu-item" onClick={onClickAboutus}>Chi siamo</div>
                <div className="menu-item" onClick={onClickCollab}>Collabora</div>
                <div className="menu-item" onClick={onClickPartner}>Partner</div>
                <div className="menu-item" onClick={onClickPrivacy}>Privacy</div>
                <div className="menu-item" onClick={onClickTerms}>T&C</div>
                <div className="menu-item" onClick={onClickSupport}>Supporto</div>
                <div className="menu-item" onClick={onClickMedia}>Media & Comunicazione</div>
            </div>
        </div>
    )

    return (

        <div>
            <div className="header">
                <div className="at-logo" onClick={onClickHome}><AffareTrattoreLogo /></div>
                <div className="pleggit-logo" onClick={onClickHome}><PleggitLogo /></div>
                <div className="pleggit-title" onClick={onClickHome}><PleggitTitle /></div>
                <div className="pleggit-menu">
                </div>
                {loggedUser && <div className="pleggit-menu-icon" onClick={logout}><ExitSVG /></div>}
                <div className="pleggit-menu-icon only-mobile" onClick={onShowSideMenu}><MenuSVG /></div>
            </div>

            {/* {sideMenuComp} */}
        </div>

    )
}
