import moment from 'moment';
import './NegotiationMessage.css'

export function NegotiationMessage({ text, datetime, me, party }) {

    let partyIndicator;
    if (!me) partyIndicator = (party == 'buyer') ? 'Il Compratore ha scritto il ' : 'Il Venditore ha scritto il '

    return (
        <div className={`negotiation-message me-${me}`}>
            {me != null && <div className='time'>{partyIndicator} {datetime ? moment(datetime, 'YYYYMMDDHHmmss').format('DD MMM. YYYY,  HH:mm') : ''}</div>}
            <div className={`negotiation-message-bubble`}>
                <div className="icon-container">
                </div>
                <div className="message">
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                </div>
            </div>
        </div>
    )

}