import './ChatBoxInput.css'
import { ReactComponent as SendSVG } from '../../../../icons/send.svg'
import { useState } from "react"
import NPIconButton from "../../../../comp/NPIconButton"
import { SellerConversationChatBox } from './SellerConversationChatBox'

export function ChatBoxInput({ placeholder, onSend, partyType }) {

    const [message, setMessage] = useState()

    return (
        <div className="chat-box-input">
            <textarea
                className='chatinputbox'
                placeholder={placeholder ? placeholder : 'Inserisci il tuo messaggio'}
                value={message}
                onChange={(v) => { setMessage(v.target.value) }}
                rows="3"
            />
            <NPIconButton image={<SendSVG />} onClick={() => {
                if (onSend) onSend(message)
            }} />
        </div>
    )

}