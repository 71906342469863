import { useEffect, useState } from "react";
import './CustomerDashboard.css';
import TranAPI from "../../../api/TranAPI";
import NPCard from "../../../comp/card/NPCard";
import { CustomerTransactionLegend, CustomerTransactions } from "./CustomerTransactions";
import Cookies from "universal-cookie";
import { isProfileCompleted } from "../../../util/UserUtil";
import MarketplaceAPI from "../../../api/MarketplaceAPI";
import ProfileAPI from "../../../api/ProfileAPI";
import { NPCardMessage } from "../../../comp/card/NPCardMessage";
import { HSpacer } from "../../../comp/layout/HSpacer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const cookies = new Cookies()

export default function CustomerDashboard() {

    const [loading, setLoading] = useState(false)

    const [transactions, setTransactions] = useState()
    const [notifications, setNotifications] = useState()
    const [missingDoc, setMissingDoc] = useState()
    const [missingPayment, setMissingPayment] = useState()

    const loggedUser = cookies.get('user')

    const history = useHistory()

    /**
     * Retrieve my transactions (as a Customer)
     */
    const reload = async () => {

        const timer = setTimeout(() => { setLoading(true) }, 500)

        const apiResponse = await new MarketplaceAPI().getMyTransactions();

        setTransactions(apiResponse.transactions)

        clearTimeout(timer)
        setLoading(false)

        // Get the user notifications
        const notificationsResponse = await new MarketplaceAPI().getMyNotifications();

        if (notificationsResponse && !notificationsResponse.code) {
            setNotifications(notificationsResponse.notifications);

            for (let notif of notificationsResponse.notifications) {
                if (notif.code == 'missing-doc') setMissingDoc(true);
                if (notif.code == 'unpaid-transactions') setMissingPayment(true);
            }
        }

    }

    useEffect(() => { reload() }, [])

    return (
        <div className="screen customer-dashboard">

            <NPCard>
                <div className="card-title">Benvenuta/o su Pleggit.</div>
                <div>
                    <p>In questa pagina puoi trovare tutte le informazioni sulle tue transazioni in corso.</p>
                    {/* <p>Per completa transparenza, puoi vedere i prezzi Pleggit sulla nostra <b><a target="_blank" href="https://pleggit.com/pricing">Pagina di Pricing</a></b>: la commissione Pleggit serve a garantire la sicurezza della tua transazione!</p> */}
                </div>
            </NPCard>

            {notifications && notifications.length > 0 && 
                <NPCard>
                    <div className="card-title">Notifiche</div>
                    {missingDoc && <NPCardMessage icon="warn" backgroundColor="complementary" text={`Devi ancora caricare ${loggedUser.userType == 'company' ? "la Visura Camerale della Società" : "un Documento di Identità"} per completare le tue transazioni in corso.`} color="complementary" actionTitle="Carica documento" onAction={() => { history.push('/docs') }} />}
                    {missingPayment && (
                        <>
                            <HSpacer />
                            <NPCardMessage icon="warn" backgroundColor="complementary" text="Non abbiamo ancora ricevuto il bonifico per alcune transazioni di compravendita" instructions="Puoi vedere la lista delle tue transazioni qui sotto. Se hai già effettuato il bonifico puoi ignorare questo messaggio: sparirà appena avremo ricevuto il bonifico." color="complementary" />
                        </>

                    )}
                </NPCard>
            }


            <NPCard>
                <div className="card-header">
                    <div className="card-title">Le tue transazioni</div>
                    <div className="button-container">
                        <CustomerTransactionLegend />
                    </div>
                </div>
                <div className="card-body">
                    <CustomerTransactions transactions={transactions} loading={loading} />
                </div>
            </NPCard>
        </div>
    )

}

