import MarketplaceAPI from '../api/MarketplaceAPI';

const uuid = require('uuid');

/**
 * This module provides a logger for "User Interaction" events. 
 * 
 * It allows for the tracking of user activity on the site in order to get anonymized analytics on what the users are doing, 
 * where they stop, etc. 
 * 
 * The goal is to get better insights into what works and what doesn't. 
 */

class UserActivityLogger {

    constructor() {
        this.sessionId = uuid.v4();
    }

    /**
     * Logs a user activity
     * @param {string} activity name of the activity
     * @param {object} data data to be logged with the event
     */
    async logActivity(activity, data) {
        await new MarketplaceAPI().postUserActivity(this.sessionId, activity, data)
    }

}

export const USER_ACTIVITIES = {
    fieldFilled: "field-filled",
    submit: "submit", 
    landingRegistrationButtonClicked: 'landing-registration-button-clicked', 
    transactionDetailClicked: 'tx-detail-clicked',
    negotiationStartClicked: 'negotiation-start-clicked'
}

export const UserActivityLoggerInst = new UserActivityLogger();