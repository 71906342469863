import { HSpacer } from '../../../../comp/layout/HSpacer';
import './Conversation.css'
import { NegotiationMessage } from "./NegotiationMessage";
import { ReactComponent as PleggitLogoSVG } from '../../../../images/logo/logo-no-color.svg'
import { ReactComponent as MessageSVG } from '../../../../icons/send.svg'
import { ReactComponent as TickSVG } from '../../../../icons/tick.svg'
import { ReactComponent as DotsSVG } from '../../../../icons/dots.svg'
import MarketplaceAPI from '../../../../api/MarketplaceAPI';
import Cookies from 'universal-cookie';
import { useEffect, useState } from 'react';
import NPIconButton from '../../../../comp/NPIconButton';
import { ChatBoxInput } from './ChatBoxInput';
import { NPLoadingBar } from '../../../../comp/NPLoadingBar';
import { SellerConversationChatBox } from './SellerConversationChatBox';

const cookies = new Cookies()

export function Conversation({ transaction, conversationId, onPriceAccepted }) {

    const [messages, setMessages] = useState([])
    const [wantToSendMessage, isWantToSendMessage] = useState(false)
    const [sendingMessage, setSendingMessage] = useState(false)
    const [loading, setLoading] = useState(true)
    const [showDetails, setShowDetails] = useState(false)

    const loggedUser = cookies.get('user');

    /**
     * Loads the converastion for the given transaction
     */
    const loadConversation = async () => {

        const result = await new MarketplaceAPI().getConversation(transaction.id, conversationId);

        setMessages(result.messages);

        setLoading(false)

    }

    /**
     * Accept the price and close the conversation. 
     * 
     * Only the Buyer can do this.
     */
    const acceptPrice = async () => {

        let partyType = 'buyer'
        if (loggedUser.userId == transaction.sellerUserId) partyType = 'seller'

        if (partyType != 'buyer') return;

        setSendingMessage(true)

        // TODO
        await new MarketplaceAPI().postMessage(transaction.id, {
            message: 'Il prezzo e le spese sono stati accettati.',
            conversationId: conversationId,
            partyType: partyType,
            acceptedPrice: true
        })

        setSendingMessage(false)

        if (onPriceAccepted) onPriceAccepted();
    }

    /**
     * Sends a new message the the counterpart
     */
    const onSendMessage = async (message) => {

        let partyType = 'buyer'
        if (loggedUser.userId == transaction.sellerUserId) partyType = 'seller'

        setSendingMessage(true);

        // Post the message
        await new MarketplaceAPI().postMessage(transaction.id, {
            message: message,
            conversationId: conversationId,
            partyType: partyType
        });

        setSendingMessage(false);
        loadConversation()

    }

    /**
     * Checks if the conversation is waiting for the counterparty to answer. 
     * This is used to show the "waiting for counterparty to answer.." sign. 
     * 
     * This is done either: 
     *  - When the buyer has written a message and not yet "accepted the last proposal"
     */
    const isConversationWaitingFor = (partyType) => {

        let lastMessage = messages[messages.length - 1];

        // Buyer
        if (partyType == 'buyer') {
            // The conversation is waiting for the Buyer if the last message was sent by the Seller
            if (lastMessage && lastMessage.uid == lastMessage.sellerUserId) return true;
            return false;
        }

        // Seller
        // The conversation is waiting for the Seller if the last message was sent by the Buyer
        if (lastMessage && lastMessage.uid == lastMessage.buyerUserId) return true;

        return false;
    }

    /**
     * Checks if the conversation is waiting for the counterparty
     */
    const isConversationWaitingForCounterparty = () => {

        let lastMessage = messages[messages.length - 1];

        // We are waiting for the counterpart only if the last message was written by the logged user
        return (lastMessage && lastMessage.uid == loggedUser.userId)
    }

    /**
     * Specifies if the user is allowed to reply with a message
     * 
     * The Buyer can reply with a message only if the Seller has sent an updated quote and if the buyer has refused it and clicks on "want to send a message"
     * The Seller can reply with a message only if the buyer has sent a question
     */
    const canReplyWithMessage = () => {

        if (transaction.priceAcceptedByBuyer === true) return false;

        if (getPartyType() == 'buyer' && wantToSendMessage && isConversationWaitingFor('buyer')) return true;

        if (getPartyType() == 'seller' && isConversationWaitingFor('seller')) return true;

        return false;
    }

    /**
     * Returns true if the negotiation can be accepted. 
     * The negotiation can be accepted only:
     *  - by the buyer
     *  - if the transaction as an updated price and shipping cost
     */
    const canNegotiationBeAccepted = () => {

        if (loggedUser.userId != transaction.buyerUserId) return false;

        if (!transaction.price || !transaction.shippingCost) return false;

        return true;

    }

    /**
     * Returns the party type of the connected user. 
     * It's either "buyer" or "seller"
     */
    const getPartyType = () => {
        if (transaction.buyerUserId == loggedUser.userId) return 'buyer'
        else return 'seller'
    }

    useEffect(() => { loadConversation() }, [])

    if (loading) return <NPLoadingBar />

    return (
        <div className='conversation'>
            <div className="conversation-title">
                <div className='logo'><PleggitLogoSVG /></div>
                <div>La tua trattativa {transaction.priceAcceptedByBuyer && '(conclusa)'}</div>
                <div style={{ flex: 1 }}></div>
                {transaction.priceAcceptedByBuyer == true &&
                    <div className='more-details' onClick={() => { setShowDetails(!showDetails) }}>
                        <DotsSVG />
                    </div>
                }
            </div>
            { /**
              * For every message, show a bubble with the message. 
              */
                (transaction.priceAcceptedByBuyer != true || showDetails == true) &&
                messages.map((msg) => {
                    return (
                        <div key={Math.random()}>
                            <NegotiationMessage
                                text={msg.msg}
                                me={msg.uid == loggedUser.userId}
                                datetime={msg.ts}
                                party={msg.uid == transaction.buyerUserId ? 'buyer' : 'seller'}
                            />
                            <HSpacer />
                        </div>
                    )
                })}
            { /**
             * If the counterparty needs to answer, show a "waiting for answer" box
             */
                isConversationWaitingForCounterparty() && transaction.priceAcceptedByBuyer != true &&
                <NegotiationMessage
                    text="In attesa della controparte.."
                />
            }
            { /**
             * For the Buyer, show the buttons that allow to either:
             * 1. Send another message to the seller
             * 2. Accept the proposal and proceed with the transaction
             */
                isConversationWaitingFor('buyer') && canNegotiationBeAccepted() && !wantToSendMessage &&
                <div className='buttons-container'>
                    <NPIconButton image={<MessageSVG />} label="Invia un nuovo messaggio" labelPosition="left" onClick={() => { isWantToSendMessage(true) }} />
                    <HSpacer size="small" />
                    <NPIconButton image={<TickSVG />} label="Accetta il prezzo e prosegui con l'acquisto" onClick={acceptPrice} />
                </div>
            }
            { /**
             * If the party can send a message, show the message box (text area)
             */
                canReplyWithMessage() && !sendingMessage && getPartyType() == 'buyer' &&
                <ChatBoxInput onSend={onSendMessage} />
            }
            { /**
             * If the Seller send a message, show the message box (text area)
             */
                canReplyWithMessage() && !sendingMessage && getPartyType() == 'seller' &&
                <SellerConversationChatBox transaction={transaction} onMessageSubmitted={loadConversation} conversationId={conversationId} />
            }


        </div>
    )

}