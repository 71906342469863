/**
 * This class obfuscate data considered personal that we don't want to save before getting a consent from the user
 */
export class Obfuscator {

    hideEmail(email) {
        if (!email || email.length == 0) return null;
        return "xxx@xxx.xxx"
    }

    hideString(data) {
        if (!data || data.length == 0) return null;
        return "xxx"
    }

    hidePhone(data) {
        if (!data || data.length == 0) return null;

        let fakeNum = ''

        for (let i = 0; i < data.length; i++) {
            if (i % 2 == 0) fakeNum += '0'
            else fakeNum += data[i]
        }

        return fakeNum;
    }
}