import './NegotiationWidget.css'
import NPCard from "../../../comp/card/NPCard";
import { NPCardMessage } from "../../../comp/card/NPCardMessage";
import { useEffect, useState } from 'react';
import { Conversation } from './parts/Conversation';
import MarketplaceAPI from '../../../api/MarketplaceAPI';
import Cookies from 'universal-cookie';
import { NPLoadingBar } from '../../../comp/NPLoadingBar';
import { SellerConversationChatBox } from './parts/SellerConversationChatBox';
import { USER_ACTIVITIES, UserActivityLoggerInst } from '../../../util/UserEventLogger';

const cookies = new Cookies()

export function NegotiationWidget({ transaction, onNegotiationUpdated, onPriceAccepted }) {

    const [interested, setInterested] = useState(false)
    const [showChat, setShowChat] = useState(true)
    const [conversations, setConversations] = useState([])
    const [loading, setLoading] = useState(true)

    const loggedUser = cookies.get('user');

    const firstMessage = "<span><p>Buongiorno.</p><p>Sono interessato a questo bene.</p><p>Vorrei chiedere la <b>conferma del prezzo</b> (<b>IVA inclusa</b>) e eventuali <b>costi di spedizione</b> (se a mio carico).</p></span>"

    /**
     * Starts the negotiation
     */
    const onStartNegotiation = async () => {

        // Register the activity
        UserActivityLoggerInst.logActivity(USER_ACTIVITIES.negotiationStartClicked, { uid: loggedUser.userId, tid: transaction.id });

        // Only the Buyer can start a negotiation
        if (loggedUser.userId != transaction.buyerUserId) return;

        // Post the first message and start the negotiation
        await new MarketplaceAPI().postMessage(transaction.id, {
            partyType: 'buyer',
            message: firstMessage,
        })

        loadConversations();

        setInterested(true)

        setTimeout(() => { setShowChat(true) }, 500);

    }

    /**
     * Retrieves all the conversation this user has access to on the transaction
     */
    const loadConversations = async () => {

        setLoading(true)

        const response = await new MarketplaceAPI().getConversations(transaction.id);

        setConversations(response.conversations)
        setLoading(false)
    }

    /**
     * Returns true if the logged user has (haD) a conversation with the counterparty on this transaction.
     */
    const hasAConversation = () => {

        if (!conversations || conversations.length == 0) return false;

        return true;
    }

    /**
     * Specifies if the logged user is the Seller
     */
    const isSeller = () => {

        if (!transaction) return false;

        return loggedUser.userId == transaction.sellerUserId;
    }

    useEffect(() => { loadConversations() }, [])

    if (loading) return <NPLoadingBar />

    return (
        <div className="negotiation-widget">
            {!hasAConversation() && !isSeller() &&
                <NPCard>
                    {!interested &&
                        <NPCardMessage
                            text={<div>Sei interessato a comprare questo bene? <span>Notifica il venditore e richiedi conferma del prezzo.</span></div>}
                            actionTitle="Sono interessato"
                            onAction={onStartNegotiation}
                            instructions={<>Questa azione <b>non è vincolante</b>. Puoi sempre decidere di non proseguire con la transazione in qualsiasi momento.</>}
                        />
                    }
                    {interested &&
                        <NPCardMessage
                            text={<>Abbiamo notificato il Venditore del tuo interesse. Riceverai una mail appena avrà risposto.</>}
                            instructions={<>Nella sezione qui sotto ("La tua trattativa") potrai controllare lo stato della richiesta.</>}
                            icon="ok"
                            noBorder={true}
                        />
                    }
                </NPCard>
            }
            {hasAConversation() &&
                <div className={`conversation-area show-${showChat}`}>
                    {conversations.map((conversation) => {
                        return (
                            <NPCard key={Math.random()}>
                                <Conversation transaction={transaction} conversationId={conversation.conversationId} onPriceAccepted={onPriceAccepted} />
                            </NPCard>
                        )
                    })}
                </div>
            }
        </div>
    )

}