import NPAPI from './NPAPI';

export default class MarketplaceAPI {

  /**
   * Creates a registration
   */
  async postRegistration(data) {

    return new NPAPI().fetch('mkt', '/registrations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => response.json());

  }

  /**
   * Posts a new transaction
   */
  async postNewTransaction(data) {

    return new NPAPI().fetch('mkt', '/me/transactions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => response.json());

  }

  /**
   * Get an Ad Image
   */
  async getAdImage(url) {

    return new NPAPI().fetch('mkt', `/adImage?url=${url}`, {
      method: 'GET',
    }).then((response) => response.json());

  }

  /**
   * Get a user's own transactions
   */
  async getMyTransactions() {

    return new NPAPI().fetch('mkt', `/me/transactions`, {
      method: 'GET',
    }).then((response) => response.json());

  }

  /**
   * Get a user's own transaction
   */
  async getMyTransaction(tid) {

    return new NPAPI().fetch('mkt', `/me/transactions/${tid}`, {
      method: 'GET',
    }).then((response) => response.json());

  }

  /**
   * Get a user's own notifications
   */
  async getMyNotifications() {

    return new NPAPI().fetch('mkt', `/me/notifications`, {
      method: 'GET',
    }).then((response) => response.json());

  }

  /**
   * Requests an OTP
   */
  async sendOTP(tid, phoneCountryCode, phone) {

    return new NPAPI().fetch('mkt', `/me/transactions/${tid}/otp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        phoneCountryCode: phoneCountryCode,
        phone: phone
      })
    }).then((response) => response.json());

  }

  /**
   * Posts a Message on the transaction
   */
  async postMessage(tid, messageData) {

    return new NPAPI().fetch('mkt', `/me/transactions/${tid}/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(messageData)
    }).then((response) => response.json());

  }


  /**
   * Requests an OTP for the Buyer (to certify goods have been received)
   */
  async sendBuyerReceivedGoodsOTP(tid) {

    return new NPAPI().fetch('mkt', `/buyer/transactions/${tid}/otp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    }).then((response) => response.json());

  }

  /**
   * Posts the property transfer 
   * 
   * @param otp the OTP
   * @oaram carrierInfo Optional carrier information (mandatory for seller). An object with {carrier: string, trackingId: string}
   */
  async postPropertyTransferWithOTP(tid, otp, carrierInfo) {

    return new NPAPI().fetch('mkt', `/me/transactions/${tid}/pt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        otp: otp,
        carrierInfo: carrierInfo
      })
    }).then((response) => response.json());

  }

  /**
   * Only for the Buyer: certify that the goods have been received and close the transaction
   * 
   * @param otp the OTP
   */
  async postSimplifiedBuyerReceivedGoods(tid, otp) {

    return new NPAPI().fetch('mkt', `/buyer/transactions/${tid}/pt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        otp: otp,
      })
    }).then((response) => response.json());

  }

  /**
   * Posts the IBAN
   * 
   * @param iban the IBAN
   */
  async postIBAN(tid, iban) {

    return new NPAPI().fetch('mkt', `/me/transactions/${tid}/iban`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        iban: iban
      })
    }).then((response) => response.json());

  }

  /**
   * Retrieves all conversations on the transaction
   * 
   * @param {string} tid the transaction id
   */
  async getConversations(tid) {

    return new NPAPI().fetch('mkt', `/me/transactions/${tid}/conversations`, { method: 'GET' }).then((response) => response.json());

  }

  /**
   * Retrieves a specific conversation 
   * 
   * @param {string} tid the transaction id
   * @param {string} conversationId the if of the conversation to retrieve
   */
  async getConversation(tid, conversationId) {

    return new NPAPI().fetch('mkt', `/me/transactions/${tid}/conversations/${conversationId}`, { method: 'GET' }).then((response) => response.json());

  }

  /**
   * Saves a user activity event
   */
  async postUserActivity(sessionId, activity, data) {

    return new NPAPI().fetch('mkt', '/activities', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        sessionId: sessionId,
        activity: activity,
        data: data
      })
    }).then((response) => response.json());

  }
}
